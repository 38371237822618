<template>
  <sand-no-data v-if="swiperData.length === 0"></sand-no-data>
  <div v-else-if="!isOpen" class="sand-swiper"
    ref="sandSwiper">
    <swiper class="sand-swiper__container" ref="mySwiper"
      @swiper="onSwiper"
      :slidesPerView="swiperOptions.slidesPerView"
      :navigation="swiperOptions.navigation"
      :modules="modules"
     >
      <swiper-slide
        v-for="(item, index) in swiperData"
        :key="index">
          <div class="sand-swiper__container--box">
            <div class="sand-swiper__container--item"
              :class="{'sand-swiper__container--horizontal': !props.isHorizontal}"
              @mouseenter="selectItem(index)"
              @click="showPage(index)">
              <img class="sand-swiper__container--img" :src="item">
              <div class="sand-swiper__container--bottom">
                {{index + 1}}/{{swiperData.length}}
              </div>
            </div>
          </div>
        </swiper-slide>
    </swiper>
    <div class="sand-swiper__btn">
      <div class="sand-swiper__btn--prev"
        @mouseenter="isHoverPrev1 = true"
        @mouseleave="isHoverPrev1 = false">
        <img :src="getImg('left', isHoverPrev1)">
      </div>
      <div class="sand-swiper__btn--next"
        @mouseenter="isHoverNext1 = true"
        @mouseleave="isHoverNext1 = false">
        <img :src="getImg('left', isHoverNext1)">
      </div>
    </div>
    <div
      class="sand-swiper__mask"
      ref="swiperMask"
      @mouseleave="outItem"
      @click="showPage()">
      <img class="sand-swiper__mask--img" :src="maskImg">
      <!-- <div class="sand-swiper__mask--bottom">
        {{maskMsg}}
      </div> -->
    </div>
    <div class="sand-swiper__page" v-show="showSwiperPage">
      <div class="sand-swiper__dialog">
        <div class="sand-swiper__dialog__header">
          <span>{{$t('report.analyse.screenshoot')}}</span>
          <el-icon @click="closePage"><Close /></el-icon>
        </div>
        <div class="sand-swiper__dialog__content">
          <swiper class="sand-swiper__page--swiper" ref="swiperPage" @swiper="onBigSwiper" :modules="modules" :navigation="swiperPageOptions.navigation" loop>
            <swiper-slide
              v-for="(item, index) in swiperData"
              :key="index">
                <img class="sand-swiper__page--img" :class="{'isWindows': props.isHorizontal, isAndroid: !props.isHorizontal}" :src="item">
                <div class="sand-swiper__page--bottom">
                  {{index + 1}}/{{swiperData.length}}
                </div>
              </swiper-slide>
          </swiper>
          <el-icon class="sand-swiper__page--prev"><ArrowLeft /></el-icon>
          <el-icon class="sand-swiper__page--next"><ArrowRight /></el-icon>
        </div>
      </div>
      <!-- <el-icon class="sand-swiper__page--prev"><ArrowLeft /></el-icon>
      <el-icon class="sand-swiper__page--next"><ArrowRight /></el-icon> -->

      <!-- <swiper class="sand-swiper__page--swiper" ref="swiperPage" @swiper="onBigSwiper" :modules="modules" :navigation="swiperPageOptions.navigation">
        <swiper-slide
          v-for="(item, index) in swiperData"
          :key="index">
            <img class="sand-swiper__page--img" :src="item">
            <div class="sand-swiper__page--bottom">
              {{index + 1}}/{{swiperData.length}}
            </div>
          </swiper-slide>
      </swiper> -->
      <!-- <img class="sand-swiper__page--prev"
        @mouseenter="isHoverPrev2 = true"
        @mouseleave="isHoverPrev2 = false"
        :src="getImg('left-big', isHoverPrev2)">
      <img class="sand-swiper__page--next"
        @mouseenter="isHoverNext2 = true"
        @mouseleave="isHoverNext2 = false"
        :src="getImg('left-big', isHoverNext2)">
      <img @click="closePage" class="sand-swiper__page--close" src="./../../assets/img/svg/close-big.svg"> -->
    </div>
  </div>
  <div v-else>
    <img
      class="sand-swiper__open"
      v-for="(item, index) in swiperData"
      :key="index"
      :src="item">
  </div>
</template>

<script setup>
import { defineProps, reactive, computed, watch, onMounted, onBeforeUnmount, toRefs } from 'vue'
import { useStore } from 'vuex'
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'
import 'swiper/swiper.min.css'
import { Navigation } from 'swiper'
import sandNoData from './sandNoData'
import { Close, ArrowRight, ArrowLeft } from '@element-plus/icons-vue'
import { isDark } from 'app/style'

const modules = [Navigation]

const props = defineProps({
  data: {
    type: Object,
    default: () => ({
      slidesPerView: 4,
      swiperData: [
        // 'https://miao.su/images/2019/09/02/earthbd90f.jpg',
        // 'https://miao.su/images/2019/09/02/earthbd90f.jpg',
        // 'https://miao.su/images/2019/09/02/earthbd90f.jpg',
        // 'https://miao.su/images/2019/09/02/earthbd90f.jpg',
        // 'https://miao.su/images/2019/09/02/earthbd90f.jpg'
      ]
    })
  },
  isHorizontal: {
    type: Boolean,
    default: true
  }
})

const store = useStore()

const state = reactive({
  swiperOptions: {
    navigation: {
      nextEl: '.sand-swiper__btn--next',
      prevEl: '.sand-swiper__btn--prev'
    },
    slidesPerView: props.data.slidesPerView || 4
  },
  swiperPageOptions: {
    navigation: {
      nextEl: '.sand-swiper__page--next',
      prevEl: '.sand-swiper__page--prev'
    },
    slidesPerView: 1,
    loop: true
  },
  maskImg: '',
  maskMsg: '',
  move: undefined,
  showTimeout: null,
  showSwiperPage: false,
  index: 0,
  isOpen: false,
  isHoverPrev1: false,
  isHoverNext1: false,
  isHoverPrev2: false,
  isHoverNext2: false,
  mySwiper: '',
  swiperMask: '',
  sandSwiper: '',
  swiper: null,
  swiperPage: null
})

const { swiperOptions, swiperPageOptions, maskImg, showSwiperPage, isOpen, isHoverPrev1, isHoverNext1, mySwiper, swiperMask, sandSwiper } = toRefs(state)

const reportState = computed(() => store.getters.getReportState)
const swiperData = computed(() => props.data.swiperData || [])

watch(reportState, () => setReportStatic())

onMounted(() => {
  if (state.swiper) {
    state.swiper.slideTo(0, 0, false)
  }
  setReportStatic()
})
function onSwiper(swiper) {
  state.swiper = swiper
}
function onBigSwiper(swiper) {
  state.swiperPage = swiper
}
function getImg (name, isHover) {
  !isDark() && (isHover = !isHover)
  return require(`@/assets/img/svg/${name}${isHover ? '-hover' : ''}.svg`)
}
function setReportStatic () {
  if (state.reportState) {
    open()
  }
}
function open () {
  state.isOpen = true
}
function selectItem (index) {
  state.move = true
  // 防止滑动太快上一个延迟时间还没结束就开始下一个,进行一个防抖,取消之前的进入动画
  clearTimeout(state.showTimeout)
  if (state.move === undefined) {
    // 首次进来就直接执行
    showMask(index)
  } else {
    // 非首次进来就给之前一个收起时间
    state.showTimeout = setTimeout(() => {
      showMask(index)
    }, 150)
  }
}
function outItem () {
  state.move = false
  state.swiperMask.style.transform = 'scale(1)'
  setTimeout(() => {
    // 如果过了这个(缩小)时间还是false的话说明鼠标没有进来过了,下次进来就是首次进来了
    if (!state.move) {
      state.swiperMask.style.display = 'none'
      state.move = undefined
      state.maskImg = ''
    }
  }, 150)
}
function showMask (index) {
  state.maskImg = swiperData.value[index]
  state.swiperMask.style.display = 'block'
  const realIndex = index - state.swiper.realIndex
  const width = (state.sandSwiper.offsetWidth - 40) / props.data.slidesPerView - 16
  state.maskMsg = `${index + 1}/${swiperData.value.length}`
  state.index = index
  state.swiperMask.style.width = width + 'px'
  state.swiperMask.style.left = 20 + width * realIndex + 16 * realIndex + 8 + 'px'
  state.swiperMask.style.transform = 'scale(1.2)'
}
function showPage (index) {
  state.showSwiperPage = true
  document.body.style.overflow = 'hidden'
  if (index) {
    state.swiperPage.slideTo(index + 1, 0, false)
  } else {
    state.swiperPage.slideTo(state.index + 1, 0, false)
  }
}
function closePage () {
  state.showSwiperPage = false
  document.body.style.overflow = ''
}

onBeforeUnmount(() => closePage())

</script>

<style lang="scss" scoped>
.sand-swiper  {
  position: relative;
  margin: 20px 0;
  &__container  {
    width: calc(100% - 40px);
    &--box  {
      padding: 0 8px;
    }
    &--item  {
      height: 0px;
      padding-bottom: calc(75% + 30px);
      box-sizing: border-box;
      position: relative;
    }
    &--horizontal  {
      padding-bottom: calc(166.6% + 30px);
    }
    &--img  {
      width: 100%;
      height: calc(100% - 30px);
      display: block;
      margin: 0 auto;
      position: absolute;
    }
    &--bottom  {
      // @include ft_color(color-main-font);
      width: 100%;
      height: 30px;
      display: inline-block;
      margin: 0 auto;
      padding: 10px auto 0px auto;
      font-size: 12px;
      text-align: center;
      line-height: 32px;
      position: absolute;
      bottom: 0;
    }
  }
  &__dialog {
    // width: calc(100vw - 372px);
    // width: 1068px;
    width: 56vw;
    top: 50%;
    transform: translateY(-50%);
    position: relative;
    margin: 0 auto;
    background: var(--detect-config-bg);
    &__header {
      border-bottom: 1px solid var(--color-border-2);
      height: 48px;
      line-height: 48px;
      padding: 0 20px;
      display: flex;
      justify-content:space-between;
      align-items:center;
      svg {
        cursor: pointer;
      }
    }
    &__content {
      padding: 24px 40px 0;
    }
  }
  &__page  {
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0px;
    top: 0;
    z-index: 100;
    background: #00000099;
    overflow: hidden;
    box-sizing: border-box;
    &--swiper {
      max-width: calc(100vw - 150px);
      position: relative;
    }
    &--img  {
      max-width: calc(100vw - 100px);
      // max-height: calc(100vh - 300px);
      display: block;
      margin: 0 auto;
      &.isWindows {
        width: 100%;
      }
      &.isAndroid {
        max-height: calc(100vh - 200px);
      }
    }
    &--bottom  {
      width: 50px;
      margin: 5px auto;
      font-size: 14px;
      text-align: center;
      height: 50px;
      line-height: 50px;
      color: var(--color-text-2);
    }
    &--close  {
      position: absolute;
      right: 30px;
      top: 30px;
      height: 50px;
      width: 50px;
    }
    &--next  {
      height: 50px;
      position: absolute;
      bottom: 2px;
      right: 35px;
      font-size: 24px;
      cursor: pointer;
      z-index:10;
      &:hover {
        color: var(--color-primary);
      }
    }
    &--prev  {
      height: 50px;
      position: absolute;
      outline: none;
      bottom: 2px;
      left: 35px;
      font-size: 24px;
      cursor: pointer;
      z-index:10;
      &:hover {
        color: var(--color-primary);
      }
    }
  }
  &__btn  {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    &--next  {
      height: 16px;
      width: 9px;
      position: absolute;
      right: 0px;
      outline: none;
      top: 50%;
      transform: translateY(-50%) rotate(180deg);
      & img  {
        margin-left: -3px;
      }
    }
    &--prev  {
      height: 16px;
      width: 10px;
      position: absolute;
      outline: none;
      top: 50%;
      transform: translateY(-50%);
      & img  {
        margin-left: -3px;
      }
    }
  }
  &__mask  {
    position: absolute;
    top: 0px;
    left: -2000px;
    z-index: 1;
    font-size: 0px;
    transition: transform 0.15s;
    height: 100%;
    &--img  {
      width: 100%;
      height: calc(100% - 30px);
    }
    &--bottom  {
      // @include bg_color(color-swiper-bottom-bg);
      // @include ft_color(color-main-font);
      width: 100%;
      height: 30px;
      font-size: 12px;
      text-align: center;
      line-height: 30px;
    }
  }
  &__open  {
    width: calc(100% - 40px);
    display: block;
    padding: 20px;
  }
}
</style>
