<template>
  <div class="leftWrap">
    <div class="detail">
      <div class="detail-name" v-if="!props.isTaskList">
        <p>
          {{computedInfo.fileName}}
          <span v-if="category === 'url'" style="color: #ff5967;">（{{ $t('other.views.reportPdf.riskUrl') }}）</span>
        </p>
      </div>
      <div class="detail-overview">
        <dashboard isShowArrow="true" :onlyIncludeStatic="props.onlyIncludeStatic" :process="score" :status="props.taskInfo.status" :combined="info.score_combined" :dynamic_score="info.dynamic_score" :score="props.summary.threat_level"></dashboard>
        <div class="detail-overview__items">
          <div class="detail-overview__top">
            <div class="detail-overview__platform">
              <span class="type">{{ $t('other.views.reportPdf.targetPlatform') }}：</span>
              <span class="name">{{computedInfo.taskOS}}</span>
            </div>
            <div class="detail-overview__type">
              <span class="type">{{category !== 'url' ? $t('other.views.reportPdf.fileType') : $t('other.views.reportPdf.taskType')}}</span>
              <span class="name">{{category !== 'url' ? computedInfo.file_type : 'URL'}}</span>
            </div>
          </div>
          <div class="detail-overview__top">
            <div class="detail-overview__platform">
              <span class="type">{{ $t('other.views.reportPdf.testEnvironment') }}：</span>
              <span class="name">{{computedInfo.environment}}</span>
            </div>
            <div class="detail-overview__type">
              <span class="type">{{ $t('other.views.reportPdf.submitTime') }}：</span>
              <span class="name">{{computedInfo.shutdown_on}}</span>
            </div>
          </div>
          <div class="detail-overview__top">
            <div class="detail-overview__platform">
              <span class="type">{{ $t('other.views.reportPdf.duration') }}：</span>
              <span class="name">{{computedInfo.continued || '-'}}</span>
            </div>
            <div class="detail-overview__type">
              <span class="type">{{ $t('other.views.reportPdf.running') }}：</span>
              <span class="name">{{computedInfo.duration || '-'}}</span>
            </div>
          </div>
          <div>
            <div style="margin-top: 10px;">
              <span style="color: #525966;">MD5：</span>
              <span class="str">{{computedInfo.md5}}</span>
            </div>
            <div style="margin-top: 10px;">
              <span style="color: #525966;">SHA1：</span>
              <span class="str">{{computedInfo.sha1}}</span>
            </div>
            <div style="margin-top: 10px;">
              <span style="color: #525966;">SHA256：</span>
              <span class="str">{{computedInfo.sha256}}</span>
            </div>
          </div>
          <div class="detail-overview__top">
            <TagList v-if="areasConfig.tag && computedInfo.tags" :data="computedInfo.tags" :showMore="computedInfo.tagsFilter" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import TagList from './tagList'
import { defineProps, computed, reactive, ref, onMounted, toRefs, watchEffect, watch } from 'vue'
import { useStore } from 'vuex'
import dashboard from '@/components/echarts/dashboard'
import dayjs from 'dayjs'
import { t } from 'app/i18n'

const store = useStore()

const props = defineProps({
  taskInfo: {
    type: Object,
    default: () => {}
  },
  summary: {
    type: Object
  },
  onlyIncludeStatic: {
    type: Boolean
  },
  progress: {
    type: Number,
    default: 100
  },
  isTaskList: {
    type: Boolean,
    default: false
  },
  isRunning: {
    type: Boolean
  },
  processes: {
    type: Array,
    default: () => []
  },
  behaviorscreenshot: {
    type: Object
  }
})
const { isRunning } = toRefs(props)
const params = ref(JSON.parse(JSON.stringify(props.summary.params)))
const configParams = ref(JSON.parse(JSON.stringify(props.summary.params)))

// watchEffect(() => {
//   // if (props.summary.params) params = ref(JSON.parse(JSON.stringify(window.originConfig)))
//   if (window.originConfig) params = ref(JSON.parse(JSON.stringify(window.originConfig)))
//   console.log(params)
// })

watch(() => props.summary, () => {
  params.value = JSON.parse(JSON.stringify(props.summary.params))
  configParams.value = JSON.parse(JSON.stringify(props.summary.params))
}, {
  deep: true
})

const state = reactive({
  popperShow: false,
  isShowUpgrade: false,
  upgradeType: '',
  dialogVisible: false,
  redetect: false,
  continued: 0,
  initTime: new Date(),
  timer: null,
  downloadDropdown: false,
  handleDropdown: false,
  shareDropdown: false,
  showPoint: false,
  showNormal: false,
  confirmDialog: false
})

const category = computed(() => {
  if (isRunning.value) {
    return props.taskInfo.category !== 'url' ? 'file' : 'url'
  } else {
    return props.summary.info.category !== 'url' ? 'file' : 'url'
  }
})

const score = computed(() => props.progress)

const areasConfig = computed(() => {
  const score = props.progress
  return {
    count: score > 30,
    tag: score > 30,
    operate: {
      show: score > 30,
      disable: score < 100
    },
    process: score === 100
  }
})

const info = computed(() => props.summary.info)

onMounted(() => {
  if (store.getters.isLogin) {
    // 获得团队信息
    store.dispatch('team/getTeamMembers')
  }
  if (isRunning.value) {
    state.timer = setInterval(() => {
      state.continued = state.continued + 1
    }, 1000)
  }
  document.title = `${t('other.views.reportPdf.taskReport')}：${computedInfo.value.fileName.length > 10 ? computedInfo.value.fileName.substring(0, 10) + '...' : computedInfo.value.fileName} - ${t('other.views.reportPdf.sandbox')}}`
})

const reported_computedInfo = computed(() => {
  const { target, machine } = info.value
  const other =
        category.value === 'file'
          ? {
            crc32: target?.file?.crc32,
            ssdeep: target?.file?.ssdeep,
            name: props.taskInfo ? props.taskInfo.filename : target?.file?.name,
            file_type: props.taskInfo.file_type
          }
          : {
            url: target.url
          }
  const env = machine.os_env || machine.tags || ''
  let environment = (Array.isArray(env) ? env : env.split(',')).join(' ')
  if (props.onlyIncludeStatic) {
    environment = t('other.views.reportPdf.staticDetection')
  }
  return {
    taskOS: props.summary.taskOS,
    fileName: props.taskInfo.detect_sample,
    md5: target?.file?.md5,
    sha1: target?.file?.sha1,
    sha256: target?.file?.sha256,
    shutdown_on: info.value.added_on || info.value.started,
    continued: (info.value.detect_duration || '0') + ' 秒',
    // ended: this.info.ended,
    environment: environment,
    // platform: other?.file_type === 'apk' ? 'Android' : other?.file_type === 'elf' ? 'Linux' : 'Windows',
    duration: info.value.duration ? `${info.value.duration} 秒` : '',
    tags: info.value.tags,
    tagsFilter: info.value.tagsFilter,
    download_url: info.value.download_urls,
    ...other
  }
})

const running_computedInfo = computed(() => {
  const env = props.taskInfo.os_env
  let environment = (Array.isArray(env) ? env : env.split(',')).join(' ')
  if (props.onlyIncludeStatic) {
    environment = t('other.views.reportPdf.staticDetection')
  } else if (Array.isArray(env) && env.length === 3) {
    environment += t('other.views.reportPdf.position')
  }
  return {
    taskOS: props.taskInfo.os,
    file_type: props.taskInfo.file_type,
    fileName: props.taskInfo.detect_sample,
    shutdown_on: props.taskInfo.added_on,
    md5: props.taskInfo.md5,
    sha1: props.taskInfo.sha1,
    sha256: props.taskInfo.sha256,
    environment: environment,
    duration: '',
    continued: props.onlyIncludeStatic ? null : spend.value + state.continued + ' 秒',
    download_url: info.value.download_urls
  }
})

const spend = computed(() => props.taskInfo.added_on ? dayjs(state.initTime).diff(dayjs(props.taskInfo.added_on), 'seconds') : 0)

// const computedInfo = isRunning.value && score.value < 100 ? running_computedInfo : reported_computedInfo
const computedInfo = computed(() => {
  if (isRunning.value && score.value < 100) {
    return running_computedInfo.value
  } else {
    return reported_computedInfo.value
  }
})

watchEffect(() => {
  if (!isRunning.value && score.value === 100) clearInterval(state.timer)
})

</script>
<style lang="scss">
@import '@/styles/common.scss';
.advanced-operate {
  border: 0 !important;
  // box-shadow:2px 2px 10px black !important;
  box-shadow: var(--shadow) !important;
  .el-popper__arrow {
    display: none;
  }
  .disabled {
    cursor: not-allowed;
    color: #525966;
  }
  .el-dropdown-menu__item {
    // padding: 5px 10px !important;
  }
  .arrow-item {
    width: 100px;
    height: 22px;
    display:flex;
    padding: 5px 20px;
    justify-content:space-between;
    align-items:center;
  }
  .width-114 {
    width: 114px;
  }
  .no-team-yet {
    width: 150px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-text-3);
    font-size: 14px;
  }
  .el-scrollbar__wrap {
    margin: 0 4px;
  }
}
.teamDropDownMenu {
  max-height: 300px;
}
.shareIcon {
  .fontRight {
    flex-direction: row-reverse;
  }
}
.noPadding {
  padding: 0 !important;
}
.qrcode {
  border: 0 !important;
  .el-popper__arrow {
    display: none;
  }
}
</style>
<style lang="scss" scoped>
.leftWrap {
  &::-webkit-scrollbar{width:0px;}
  // width: 440px;
  // max-height: calc(100vh - 104px);
  overflow-y: auto;
  // background-color: var(--detect-config-bg);
}
.detail {
  border: 1px solid var(--color-border-2);
  // padding: 0 20px 20px;
  &-name {
    height:48px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--color-border-2);
    svg {
      padding-right: 10px;
      color: var(--color-text-3);
      width: 18px;
      height: 18px;
    }
    p {
      font-size: 14px;
      line-height: 18px;
      color: var(--color-text-2);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  &-overview {
    display: flex;
    // margin-bottom: 16px;
    padding: 10px 20px;
    // min-height: 180px;
    &__type {
      overflow: hidden;
      img {
        display:inline-block;
        width: 14px;
        height: 14px;
        // position: relative;
        // left: -80px;
        // filter: drop-shadow(#7c7f8d 80px 0);
      }
      .type {
        // margin-top: 8px;
      }
    }
    svg {
      color: var(--color-text-3);
    }
    &__chart {
      // flex: 1;
    }
    &__items {
      flex: 2;
      padding-left: 16px;
    }
    &__top {
      // height: 78px;
      display: flex;
      padding-top: 10px;
      .detail-overview__platform {
        width: 50%;
        // border-left: 1px solid var(--color-border-2);
        // padding-left: 20px;
        font-size: 14px;
        .type {
          color: var(--color-text-2);
        }
        .name {
          color: var(--color-text-2);
        }
      }
      .detail-overview__platform {
        width: 50%;
        // border-left: 1px solid var(--color-border-2);
        // padding-left: 20px;
        font-size: 14px;
        .type {
          color: var(--color-text-2);
        }
        .name {
          color: var(--color-text-2);
        }
      }
    }
    &__bottom {
      display: flex;
      flex-flow: wrap;
      margin-top: 18px;
      .items {
        margin-top: 12px;
        width: 84px;
        span {
          margin-left: 8px;
          color: var(--color-text-2);
        }
        &.red {
          svg {
            color: #ff5967
          }
          span {
            color: #ff5967;
          }
        }
      }
    }
    &__operate {
      margin-top: 16px;
      display:flex;
      height: 48px;
      align-items: center;
      flex-direction: row-reverse;
      background: var(--detect-config-bg);
      box-shadow: 0 -1px 0 0 var(--color-border-2);
      .redetect-disable {
        cursor: not-allowed !important;
        color: var(--color-text-3) !important;
        &:hover {
          color: var(--color-text-3) !important;
        }
      }
      .analyseSummary-content-btn-item {
        margin-left: 20px;
        font-size: 14px;
        color: var(--color-text-2);
        cursor: pointer;
        &:hover {
          color: var(--color-primary);
        }
        .el-icon {
          position: relative;
          top: 2px;
        }
        .el-dropdown-link {
          line-height: 20px;
          color: var(--color-text-2);
          i {
            transition: transform 0.3s;
          }
          &.active {
            color: #00ab7a;
            i {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
  &-center {
    font-size: 14px;
    color: var(--color-text-2);
    // padding: 10px 0;
    &__detail {
      cursor: pointer;
      color: #00ab7a;
    }
    span {
      padding-right: 10px;
      display: inline-block;
      height: 30px;
      line-height: 30px;
    }
    &__info {
      padding-top: 10px;
      box-shadow: 0 -1px 0 0 var(--color-border-2);
    }
    &__string {
      margin-top: 10px;
      padding: 8px 0;
      box-shadow: 0 -1px 0 0 var(--color-border-2);
      div {
        display:flex;
        align-items: center;
        &:hover {
          & span:nth-child(3) {
            display: block;
          }
          & :deep(.copy-icon) {
            display: block;
          }
          svg {
            display: block;
          }
        }
        & span:first-child {
          width:54px;
        }
        & span:nth-child(2) {
          padding: 0;
          max-width: 480px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        & :deep(.copy-icon) {
          color: #00ab7a;
          display: none;
          font-size: 16px;
        }
        svg {
          display: none;
          color: #00ab7a;
          width: 2em;
          font-size: 16px;
          cursor: pointer;
          &:hover {
            filter:brightness(1.2);
          }
        }
      }
    }
  }
  &-bottom {
    padding: 4px 0px 20px;
    font-size: 14px;
    color: var(--color-text-2);
    box-shadow: 0 -1px 0 0 var(--color-border-2);
    &__content {
      box-sizing: border-box;
      min-height: 180px;
      height: calc(100vh - 748px);
      background: var(--color-bg-3);
      border: 1px solid var(--color-border-2);
      border-radius: 2px;
      padding: 6px 14px;
      overflow-y: auto;
    }
    &__listWrap {
      div {
        height:32px;
        display: flex;
        align-items: center;
        & :nth-child(1) {
          width: 40px;
          text-align: right;
        }
        & :nth-child(3) {
          flex: 1;
          display: inline-block;
          cursor: pointer;
          line-height: 18px;
          max-width: 325px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        svg {
          width: 14px;
          margin: 0 8px;
        }
      }
    }
  }
}
.deleteContent {
  display: flex;
  svg {
    margin-right: 8px;
    font-size: 20px;
    color:#fa8c16;
  }
}
:deep(.share-network-weibo) {
  width: 100%;
  cursor: pointer;
  &:hover {
    color: #00ab7a;
  }
}
:deep(.share-network-twitter) {
  width: 100%;
  cursor: pointer;
  &:hover {
    color: #00ab7a;
  }
}
:deep(.share-network-facebook) {
  width: 100%;
  cursor: pointer;
  &:hover {
    color: #00ab7a;
  }
}
</style>
