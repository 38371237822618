<template>
  <div class="behavior-summary-wrap">
    <Widgest isOpenAll widgestType="outer" :isBig="true">
      <template v-slot:title>{{$t('report.analyse.summary.title')}}</template>
      <SandTab v-if="tabData.length" :data="tabData" :defaultName="defaultName" @change="handleTabChange"></SandTab>
      <SandTableCollapse2
        :data="collapseData"
        @change="handleCollapseChange"
        class="behavior-summary-collapse"
      >
        <div class="behavior-event-filter">
          <SearchInput
            @keydown="getMainkey"
            class="filter-input"
            ref="searchInput"
            :key="eventType"
          />
          <div>
            <Pagination
              @handleSizeChange="handleSizeChange"
              @handleCurrentChange="handleCurrentChange"
              :totalSize="totalSize"
              :current_page="currentPage"
              :hide_pageSize="true"
              :jumper="true"
            />
          </div>
        </div>
        <template v-if="!events || events.length === 0">
          <div class="behavior-event-wrap">
            <sandNoData :text="$t('no_result_found')" />
          </div>
        </template>
        <template v-else>
          <div class="behavior-event-wrap" ref="eventWrap">
            <div
              class="behavior-event-item"
              v-for="(item, index) in events"
              :key="index"
            >
              <div class="behavior-event-left">
                <el-tooltip :effect="$isDark() ? 'dark' : 'light'" :content="$isZh() ? ((eventList[item.event] && eventList[item.event].zhcn) || item.event) : ((eventList[item.event] && eventList[item.event].enus) || item.event)" placement="top">
                  <!-- <div class="event-name-wrap"> -->
                    <span class="event-name">{{
                      $isZh() ? ((eventList[item.event] && eventList[item.event].zhcn) || item.event) : ((eventList[item.event] && eventList[item.event].enus) || item.event)
                    }}</span>
                  <!-- </div> -->
                </el-tooltip>
              </div>
              <div class="behavior-event-right">
                <div class="behavior-event-right_mainkey" v-if="item.mainkey">
                  {{ item.mainkey }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </SandTableCollapse2>
    </Widgest>
  </div>
</template>

<script>
// 分析概览中 行为总览 模块
import sandNoData from '@/components/report/sandNoData'
import SandTab from '@/components/report/sandTab'
import SandTableCollapse2 from '@/components/report/sandTableCollapse2'
import { getTaskReportBehaviorEventsByPid } from 'services/report'
import eventDesc from '@/assets/markdown/event_description.json'
import { debounce } from 'lodash'
import Pagination from '@/components/common/Pagination'
import { t } from 'app/i18n'

export default {
  name: 'BehaviorSummary',
  components: {
    SandTab,
    SandTableCollapse2,
    sandNoData,
    Pagination
  },
  props: {
    progressanalyse: {
      type: Object
    },
    taskid: {
      type: Number
    }
  },
  computed: {
    tabData() {
      // 如果后端没有返回每个类型的数量, 则使用固定的tab表头
      if (this.progressanalyse.tab_data) {
        return this.progressanalyse.tab_data.map((item) => {
          return {
            label: `${item.name} (${item.value})`,
            name: item.key
          }
        })
      }
      return []
    },
    taskOS() {
      return this.progressanalyse.taskOS
    },
    processes() {
      return this.progressanalyse.processes || []
    },
    collapseData() {
      const hasDataProcess = this.processes.filter(
        (processItem) =>
          processItem.events_info.find((events) => events.key === this.eventType) &&
          processItem.events_info.find((events) => events.key === this.eventType).value
      )
      const collapseData = hasDataProcess.map((item) => ({
        process: `${item.process_name} (${t('report.analyse.summary.process_id')}: ${item.pid})`,
        id: item.pid
      }))
      return {
        collapseData,
        columnData: [
          {
            prop: 'process',
            label: t('report.analyse.summary.process_name'),
            width: undefined,
            align: 'left',
            headAlign: 'left'
          }
        ],
        tableProps: {
          stripe: false,
          showHeader: false
        }
      }
    },
    taskId() {
      return this.$route.params.taskId || this.taskid
    }
  },
  data() {
    return {
      argumentsList: eventDesc.argument,
      eventList: eventDesc.event,
      category: eventDesc.category.slice(0, 6),
      defaultName: this.progressanalyse.tab_data ? this.progressanalyse.tab_data[0].key : 'file',
      eventType: this.progressanalyse.tab_data ? this.progressanalyse.tab_data[0].key : 'file',
      pid: '',
      events: [],
      mainkey: '',
      totalSize: 0,
      currentPage: 1,
      isShowTooltip: false
    }
  },
  watch: {
    eventType() {
      this.$refs.searchInput && this.$refs.searchInput.resetInput()
      this.getEventById()
    },
    pid() {
      this.getEventById()
    },
    mainkey() {
      this.getEventById()
    },
    progressanalyse: {
      handler() {
        this.eventType = this.progressanalyse.tab_data ? this.progressanalyse.tab_data[0].key : 'file'
      },
      deep: true
    }
  },
  methods: {
    handleTabChange(tab) {
      if (typeof tab === 'string') {
        this.currentPage = 1
        this.eventType = tab
      }
    },
    async getEventById() {
      if (!this.pid) return
      console.time('API Request')
      await getTaskReportBehaviorEventsByPid({
        taskid: this.taskId,
        pid: this.pid,
        eventType: this.eventType,
        mainkey: this.mainkey,
        page: this.currentPage
      }).then((res) => {
        console.timeEnd('API Request')
        this.events = res.events && res.events[this.eventType]
        this.totalSize = res.count
      })
      if (this.$refs.eventWrap) {
        document.querySelector('.behavior-event-wrap').scrollTo({ top: 0 })
      }
    },
    handleCollapseChange(item) {
      if (item && !item.id) return
      this.$refs.searchInput && this.$refs.searchInput.resetInput()
      this.currentPage = 1
      // this.mainkey = ''
      item && (this.pid = item.id)
    },
    handleSizeChange(size) {},
    handleCurrentChange(page) {
      this.currentPage = page
      this.getEventById(page)
    },
    getMainkey(mainkey) {
      console.log(mainkey)
      this.currentPage = 1
      this.mainkey = mainkey
    }
  },
  mounted() {
    this.pid = this.processes.length > 0 && this.processes[0].pid
    this.getMainkey = debounce(this.getMainkey, 300)
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';

.behavior-summary-wrap {
  .behavior-summary-collapse {
    :deep(.el-collapse) {
      border: none;
    }
    :deep(.el-collapse-item) {
      margin-bottom: 10px;
      border-bottom-width: 1px;

      .el-collapse-item__header {
        background: var(--color-bg-3);
        margin: 0;
        padding-right: 10px;
      }

      &.is-active {
        .el-collapse-item__header {
          // background: #2A3257;
        }
      }
    }

    :deep(.el-collapse-item__content) {
      border-top: 0;
      padding: 0;

      .behavior-event-android {
        padding: 20px 0;
      }

      .behavior-event-filter {
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        // border-bottom: 1px solid #2D3864;

        .filter-input {
          width: 200px;
        }
      }

      .behavior-event-wrap {
        max-height: 600px;
        @include scroll_bar();
        overflow: auto;

        .behavior-event-item {
          display: flex;
          color: var(--color-text-2);
          font-size: 14px;
          min-height: 40px;
          align-items: center;
          line-height: 20px;
          padding: 5px 0;
          &+.behavior-event-item {
            // border-top: 1px solid #2D3864;
          }

          .behavior-event-left {
            box-sizing: border-box;
            flex-shrink: 0;
            margin-left: 10px;
            width: 200px;
            .event-name {
              display: inline-block;
              // background: #3f425180;
              background: var(--color-bg-3);
              border-radius: 2px;
              padding: 8px 12px;
              color: var(--color-text-1);
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              box-sizing: border-box;
              max-width: 150px;
            }
          }

          .behavior-event-right {
            color: var(--color-text-2);
            flex: 1;

            .behavior-event-right_mainkey {
              word-break: break-all;
            }
          }
        }
      }
    }
  }
  :deep(.sand-tab) {
    .el-tabs__nav-wrap.is-scrollable {
      padding: 0 40px 0 30px;
    }
    .el-tabs__nav-next, .el-tabs__nav-prev {
      width: 30px;
      top: -5px;
    }
    .el-tabs__nav-prev {
      text-align: right;
    }
  }
}
</style>
