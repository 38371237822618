<template>
  <div class="sandNetwork-wrap">
        <div class="sandNetwork-table">
          <div class="sandNetwork-header">
            <div>
              <span
                class="header-col"
                :style="`
                  width: ${item.width ? getWidth(item.width) : '0px'};
                  flex-grow: ${item.width ? '0' : item.col ? item.col : '1'};
                  text-align: ${item.align ? item.align : 'left'};
                  display: flex;
                  align-items: center;
                `"
                v-for="(item,index) in header" :key="index"><span style="padding-left: 10px;">{{item.label}}</span>
                  <el-dropdown @command="changeProtocal" class="dropdown-wrapper" placement="bottom" popper-class="tcpPopper">
                    <el-icon class="arrow-icon" v-if="item.filter" style="margin-left: 4px;"><ArrowDown /></el-icon>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item :class="{ 'is-active': props.connectType === 'tcp' }"  command="tcp">TCP</el-dropdown-item>
                        <el-dropdown-item :class="{ 'is-active': props.connectType === 'udp' }" command="udp">UDP</el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </span>
            </div>
          </div>
          <Loading class="analyse-loading" v-if="loading" :loading="loading" />
          <template v-else>
            <sand-no-data v-if="isEmpty(networkData)" :isFullscreen="type === 'network'"/>
            <el-collapse v-else v-model="collapseVal" accordion>
              <el-collapse-item
                v-for="(collapse, index) in networkData"
                :key='index'
                :name="index"
                :class="[type === 'network' ? '':'none-arrow']"
                @click="toDetail(index)"
              >
                <template #title>
                  <div class="sand-tableCollapse__header">
                    <div
                      class="sand-tableCollapse__header--item"
                    >
                      <span
                        class="sand-tableCollapse__header--text"
                        :style="`
                          width: ${item2.width ? getWidth(item2.width) : '0px'};
                          flex-grow: ${item2.width ? '0' : item2.col ? item2.col : '1'};
                          text-align: ${item2.align ? item2.align : 'left'};
                        `"
                        v-for="(item2,index2) in header"
                        :key="index2"
                        >
                        <el-tooltip :effect="$isDark() ? 'dark' : 'light'" :content="$t('dialog.detect.label')" placement="top">
                          <svg-icon class="detect" @click.stop="detect(collapse.uri)" v-if="item2.type === 'detect'" name="tijiaojiance"></svg-icon>
                        </el-tooltip>
                        <!-- <el-tooltip :effect="$isDark() ? 'dark' : 'light'" :content="$t('ti')" placement="top">
                          <svg-icon @click.stop="toTi(collapse[detailModule[0].ipProp])" v-if="item2.type === 'ti'" name="sousuoti"></svg-icon>
                        </el-tooltip> -->
                        <span class="sand-tableCollapse__header--tags" :class="{'margin12' : props.type === 'network'}" v-if="item2.prop === 'severity'" :style="{color:riskStyle(collapse[item2.prop]).color,borderColor:riskStyle(collapse[item2.prop]).border}">{{riskStyle(collapse[item2.prop]).name}}</span>
                        <span class="sand-tableCollapse__header--domains" v-else-if="item2.prop === 'domain'">
                          <span class="link" @click.stop="showDomainDetail(collapse['domain_list'][0])" v-if="collapse['domain_list'].length">{{ collapse['domain_list'][0].request }}</span>
                          <span v-else class="none">-</span>
                          <el-popover
                            placement="bottom"
                            trigger="hover"
                            width="auto"
                          >
                            <template #reference>
                              <span v-if="collapse['domain_list'].length > 1" class="more"><span style="position: relative;top: -1px;">+</span>{{ `${collapse['domain_list'].length - 1}` }}</span>
                            </template>
                            <div style="max-width: 266px;display:flex;flex-direction: column;gap: 8px;">
                              <span v-for="(item, index) in collapse['domain_list'].slice(1)" @click.stop="showDomainDetail(item)" style="display: block;cursor: pointer;color: var(--color-primary);white-space: nowrap;overflow: hidden;text-overflow: ellipsis;" :key="index">
                                {{ item.request }}
                              </span>
                            </div>
                          </el-popover>
                        </span>
                        <span v-else-if="item2.prop === 'dst' || item2.prop === 'analyse' || item2.prop === 'dst_ip'">
                          <span class="sand-tableCollapse__header--dst" @click.stop="showIpDetail(collapse?.host_info)" v-if="collapse[item2.prop]">{{collapse[item2.prop]}}</span>
                          <span v-else class="none">-</span>
                        </span>
                        <span class="font" v-else>{{collapse[item2.prop]}}</span>
                      </span>
                    </div>
                  </div>
                </template>
                <template v-if="type === 'network'">
                  <div class="content" v-for="(modules, idx) in detailModule" :key="idx">
                    <template v-if="modules.type === 'label'">
                      <div v-if="!modules.hideTitle" class="h2">{{modules.name}}</div>
                      <div class="detail">
                        <div class="item" v-for="(val, key, index) in modules.map" :key="index">
                          <div class="label">{{val}}</div>
                          <div class="value" v-if="key === 'severity'" :style="{color: riskStyle(collapse[key]).color}">{{riskStyle(collapse[key]).name}}</div>
                          <div class="value" style="flex: 1;" v-else-if="key === 'raw'">
                            <div class="code-pre" style="width: 100%;">
                              <pre :key="Math.random() + idx">{{ collapse[key] }}</pre>
                            </div>
                          </div>
                          <div class="value process" v-else-if="key === 'process_list'">
                            <template v-if="collapse[key].length">
                              <div v-for="(item, index) in collapse[key]" @click="toProcess(item)" :key="index">
                                <span>{{ item.pid }}</span>
                                <span class="gap">|</span>
                                <span>{{ item.process_name }}</span>
                              </div>
                            </template>
                            <span v-else>-</span>
                          </div>
                          <div class="value" v-else>
                            <div :class="{font: key === 'uri'}" v-html="collapse[key] || '-'"></div>
                            <el-tooltip :effect="$isDark() ? 'dark' : 'light'" :content="$t('ti')" placement="top">
                              <svg-icon v-if="toTiProp.includes(key)" name="sousuoti" @click="toTi(collapse[key])"></svg-icon>
                            </el-tooltip>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-if="modules.type === 'code'">
                      <div class="h2">{{modules.name}}</div>
                      <div class="code-pre">
                        <pre>{{ collapse[modules.prop] }}</pre>
                      </div>
                    </template>
                  </div>
                </template>
                <template v-if="type === 'overview'">
                  <span class="row-col" v-for="(item2,index2) in header" :key="index2">{{collapse[item2.prop]}}</span>
                </template>
              </el-collapse-item>
            </el-collapse>
          </template>
        </div>
        <div class="pagination-wrap">
          <Pagination
            v-if="!loading"
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
            :totalSize="totalCount"
            :current_page="currentPage"
            :pageSizes="[10, 50]"
            :page-size="pageSize"
          />
        </div>
    <redetect
      v-if="state.redetect"
      v-model:show="state.redetect"
      redetectType="url"
      :url="detect_url"
    ></redetect>
    <el-dialog :title="detailTitle" :model-value="showDetail" @close="showDetail = false" class="network-detail" :width="width">
      <div class="detail">
        <div class="item" v-for="(val, key, index) in detailMap" :key="index">
          <div class="label">{{val}}</div>
          <div class="value" v-if="key === 'severity'" :style="{color: riskStyle(detail[key]).color}">{{riskStyle(detail[key]).name}}</div>
          <div class="value" style="flex: 1;" v-else-if="key === 'raw'">
            <div class="code-pre" style="width: 100%;">
              <pre :key="Math.random() + idx">{{ detail[key] }}</pre>
            </div>
          </div>
          <div class="value" v-else>
            <div :class="{font: key === 'uri'}" v-html="detail[key]"></div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import { getTaskReportNetwork } from 'services/report'
import Pagination from '@/components/common/Pagination'
import sandNoData from '@/components/report/sandNoData'
import redetect from '@/components/report/redetect'
import dayjs from 'dayjs'
import { ref, reactive, toRefs, defineProps, computed, watch, onMounted, unref, nextTick, defineEmits } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import emitter from 'app/emitter'
import { t, isZh } from 'app/i18n'
import { useGetters } from '@/hooks/useVuex'
import { checkLogin } from 'services/auth'
import { ArrowDown } from '@element-plus/icons-vue'

const route = useRoute()
const router = useRouter()
const store = useStore()
const showDetail = ref(false)
const detail = ref()
const detailMap = ref()
const { page, index } = useGetters('report', ['page', 'index'])
const emits = defineEmits(['changeConnectType'])

const props = defineProps({
  tab: {
    type: String,
    default: 'dns'
  },
  header: {
    type: Array
  },
  totalCount: {
    type: Number
  },
  taskid: {
    type: Number
  },
  type: {
    type: String
  },
  connectType: {
    type: String,
    default: 'tcp'
  }
})

const state = reactive({
  networkData: [],
  pageSize: props.type === 'network' ? 50 : 10,
  currentPage: page.value,
  loading: true,
  // toTiProp: ['src_ip', 'dst_ip', 'ip', 'area_name', 'dst', 'src'],
  toTiProp: [],
  collapseVal: props.type === 'network' ? index.value : null,
  redetect: false,
  detect_url: ''
})

onMounted(() => getTaskReportNetworkFn())
const { networkData, pageSize, currentPage, loading, toTiProp, collapseVal, detect_url } = toRefs(state)
const { header, totalCount, taskid, type, tab } = toRefs(props)

const taskId = computed(() => route.params.taskId || taskid.value)
const detailTitle = ref()
const width = ref()
const showDomainDetail = (data) => {
  detailTitle.value = '域名信息'
  detailMap.value = {
    area_name: $tt('dns.area_name'),
    response: $tt('dns.response'),
    analyse: $tt('dns.analyse')
  }
  detail.value = {
    area_name: data.request,
    response: data.answers.map(item => `${item.type} ${item.data}`).join('\n'),
    analyse: data.ip
  }
  width.value = 500
  showDetail.value = true
}
const showIpDetail = (data) => {
  detailTitle.value = 'IP 信息'
  detailMap.value = {
    ip: $tt('hosts.ip'),
    country: $tt('hosts.country'),
    city: $tt('hosts.city'),
    isp: 'ISP'
  }
  detail.value = {
    ip: data.ip,
    country: data.country,
    city: data.city,
    isp: data.isp
  }
  width.value = 400
  showDetail.value = true
}
const changeProtocal = v => {
  emits('changeConnectType', v)
  nextTick(() => {
    handleCurrentChange(1, true)
  })
}
const $tt = v => t(`report.network.${v}`)
const detailModule = computed(() => {
  switch (props.tab) {
    case 'hosts':
      return [{
        ipProp: 'ip',
        name: $tt('hosts.info'),
        type: 'label',
        map: {
          ip: $tt('hosts.ip'),
          country: $tt('hosts.country'),
          city: $tt('hosts.city'),
          isp: 'ISP',
          process_list: $tt('tcp.process')
        }
      }]
    case 'traffic.alerts':
      return [
        {
          ipProp: 'dst_ip',
          name: $tt('traffic.alerts.info'),
          type: 'label',
          map: {
            src_ip: $tt('traffic.alerts.src_ip'),
            src_port: $tt('traffic.alerts.src_port'),
            dst_ip: $tt('traffic.alerts.dst_ip'),
            dst_port: $tt('tcp.dport'),
            protocol: $tt('traffic.alerts.protocol')
          }
        },
        {
          name: $tt('traffic.alerts.detect'),
          type: 'label',
          map: {
            signature: $tt('traffic.alerts.signature'),
            category: $tt('traffic.alerts.category'),
            severity: $tt('traffic.alerts.severity'),
            timestamp: $tt('traffic.alerts.timestamp')
          }
        },
        {
          name: $tt('traffic.alerts.payload'),
          type: 'code',
          prop: 'payload'
        }
      ]
    case 'dns':
      return [
        {
          ipProp: 'area_name',
          name: $tt('dns.info'),
          type: 'label',
          map: {
            area_name: $tt('dns.area_name'),
            response: $tt('dns.response'),
            analyse: $tt('dns.analyse'),
            process_list: $tt('tcp.process')
          }
        }
      ]
    case 'http':
    case 'https':
      return [
        {
          name: $tt('http.info'),
          type: 'label',
          map: {
            uri: 'URI'
          }
        },
        {
          name: $tt('http.content'),
          type: 'code',
          prop: 'data'
        },
        {
          name: $tt('tcp.process'),
          type: 'label',
          hideTitle: true,
          map: {
            process_list: $tt('tcp.process')
          }
        }
      ]
    case 'tcp':
    case 'udp':
      return [{
        ipProp: 'dst',
        name: $tt('tcp.info'),
        type: 'label',
        map: {
          src: $tt('tcp.src1'),
          sport: $tt('tcp.sport'),
          dst: $tt('tcp.dst1'),
          dport: $tt('tcp.dport'),
          protocol: $tt('tcp.protocol1'),
          process_list: $tt('tcp.process')
        }
      }]
    case 'icmp':
      return [{
        ipProp: 'dst',
        name: $tt('icmp.info'),
        type: 'label',
        map: {
          src: $tt('icmp.src'),
          dst: $tt('icmp.dst'),
          type: $tt('icmp.type'),
          data: $tt('icmp.data')
        }
      }]
    case 'ftp':
      return [
        {
          ipProp: 'dst',
          name: $tt('icmp.info'),
          type: 'label',
          map: {
            src: $tt('icmp.src'),
            sport: $tt('tcp.sport'),
            dst: $tt('icmp.dst'),
            dport: $tt('tcp.dport')
          }
        },
        {
          type: 'code',
          name: $tt('ftp.command'),
          prop: 'cmd'
        }
      ]
    case 'smtp':
      return [{
        ipProp: 'dst',
        name: $tt('smtp.info'),
        type: 'label',
        map: {
          dst: $tt('icmp.dst'),
          raw: $tt('smtp.raw')
        }
      }]
    case 'irc':
      return [{
        name: $tt('irc.info'),
        type: 'label',
        map: {
          command: $tt('irc.command'),
          type: $tt('irc.type'),
          params: $tt('irc.params')
        }
      }]
  }
  return ''
})
watch(tab, () => {
  pageSize.value = props.type === 'network' ? 50 : 10
  // if (tab.value === 'tcp') {
  //   emits('changeConnectType', 'tcp')
  // }
  handleCurrentChange(1, true)
})
watch(taskId, () => {
  pageSize.value = props.type === 'network' ? 50 : 10
  handleCurrentChange(1, true)
})
watch(() => router.currentRoute.value.path, (toPath) => {
  // handleCurrentChange(page, true)
  state.collapseVal = index.value
})

// 分析概览网络分析切换tab loading滚动条闪动
watch(networkData, (v) => {
  if (props.type === 'network') return
  nextTick(() => {
    document.querySelector('.sandNetwork-wrap').style.height = `${v.length * 49 + 104}px`
  })
})

function isEmpty (target) {
  const type = Object.prototype.toString.call(target).slice(8, -1).toLowerCase()
  switch (type) {
    case 'object':
    case 'array':
    case 'string':
      return Object.keys(target).length === 0
    case 'undefined':
    case 'null':
      return true
    default:
      return false
  }
}
function detect(url) {
  checkLogin().then((res) => {
    if (!res.is_login) {
      emitter.emit('showLogin')
    } else {
      state.detect_url = url
      state.redetect = true
    }
  })
}
function toDetail(index) {
  if (props.type === 'network') return false
  store.commit('report/SET_NETWORK_DETAIL', { tab: props.tab, page: currentPage.value, index })
  emitter.emit('tabChange', 'network')
}
// function toUnicode(val) {
//   var strInput = val

//   var nInputLength = strInput.length

//   if (nInputLength % 2 === 0) { // 当输入够偶数位；
//     var StrHex = ''

//     for (var i = 0; i < nInputLength; i = i + 2) {
//       var str = strInput.substr(i, 2) // 16进制；

//       // StrHex = StrHex + .toString(16);

//       var n = parseInt(str, 16)// 10进制；

//       StrHex = StrHex + String.fromCharCode(n)
//     }

//     return StrHex
//   }
// }
function toProcess(data) {
  store.commit('report/SET_CURRENT_PID', data.pid)
  emitter.emit('tabChange', 'behavior')
}
function riskStyle(data) {
  switch (data) {
    case 3:
      // return { name: t('risk.low'), border: '#5a5e6d', color: '#b8bbcc' }
      return { name: t('risk.low'), border: 'var(--color-info-border)', color: 'var(--color-info-text)' }
    case 2:
      return { name: t('risk.middle'), border: '#c2aa6f', color: '#d9b12f' }
    case 1:
      return { name: t('risk.high'), border: '#eb726c', color: '#ff6a63' }
    default:
      return { name: t('risk.not_detected'), border: '', color: '#5a5e6d' }
  }
}
async function getTaskReportNetworkFn (isload) {
  if (isload) { loading.value = true }
  if (!taskId.value) return
  const protocol = props.tab === 'tcp' ? props.connectType : props.tab
  await getTaskReportNetwork(taskId.value, {
    page: currentPage.value,
    size: pageSize.value,
    protocol,
    lang: isZh() ? 'zh' : 'en'
  }).then(res => {
    if (!isEmpty(res.network[protocol])) {
      if (protocol === 'dns') {
        networkData.value = res.network.dns.map(item => {
          return {
            ...item,
            area_name: item.request,
            response: item.answers.map(item => `${item.type} ${item.data}`).join('\n'),
            analyse: res.network.domainlookups[item.request]
          }
        })
      } else if (protocol === 'traffic.alerts') {
        // const preMap = {
        //   0: 0,
        //   1: 4,
        //   2: 2,
        //   3: 4
        // }
        networkData.value = res.network[protocol].map(item => {
          // item.payload = item.payload.map(v => {
          //   return v.reduce((prev, cur, index, arr) => {
          //     let whiteSpace = ''
          //     for (let i = 0; i < preMap[index]; i++) {
          //       whiteSpace += ' '
          //     }
          //     return prev + whiteSpace + cur
          //   }, '')
          // })
          // item.payload = item.payload.join('\n')
          return {
            ...item.alert,
            ...item,
            timestamp: item.timestamp ? dayjs(item.timestamp).format('YYYY-MM-DD HH:mm:ss') + '.' + item.timestamp.split('.')[1].split('+')[0] : '-'
          }
        })
      } else if (protocol === 'ftp') {
        res.network.ftp.map(v => {
          v.cmd = v.cmd.join('\n')
          return v
        })
        networkData.value = res.network[protocol]
      } else if (protocol === 'tcp' || protocol === 'udp') {
        res.network[protocol].map(v => {
          v.protocol = v.protocol?.toUpperCase()
          return v
        })
        networkData.value = res.network[protocol]
      } else {
        networkData.value = res.network[protocol]
      }
    } else {
      networkData.value = []
    }
    loading.value = false
  })
}
function handleSizeChange (size) {
  pageSize.value = size
  handleCurrentChange(1)
}
function handleCurrentChange (page, isload) {
  currentPage.value = unref(page)
  getTaskReportNetworkFn(isload)
  collapseVal.value = ''
}
</script>
<style lang="scss">
.tcpPopper {
  .is-active {
    background-color: var(--el-dropdown-menuItem-hover-fill);
    color: var(--el-dropdown-menuItem-hover-color);
  }
}
</style>
<style lang="scss" scoped>
[lang='en'] {
  .sandNetwork-wrap .sandNetwork-table .content .detail .item .label{
    width: 110px;
  }
}
  .sandNetwork-wrap {
    .analyse-loading {
      height: auto;
      padding-top: 250px;
    }
    .sandNetwork-table {
      color: var(--color-text-2);
      font-size: 14px;
      .sandNetwork-header {
        color: var(--color-text-2);
        background-color: var(--color-bg-3);
        padding: 0 10px 0 0;
        & >div {
          height: 48px;
          line-height: 48px;
          display: flex;
          width: calc(100% - 65px);
          padding-left: 15px;
        }
        .header-col {
          // padding-left: 10px;
          line-height: 28px;
          flex: 1;
          .dropdown-wrapper:hover .arrow-icon {
            transform: rotate(180deg);
          }
          .arrow-icon {
            transition: transform 0.3s ease;
          }
        }
      }
      .none-arrow {
        :deep(.el-collapse-item__arrow) {
          display: none;
        }
      }
      & :deep(.el-collapse), & :deep(.el-collapse-item__header), & :deep(.el-collapse-item__wrap) {
        border: 0;
        background: transparent;
      }
      & :deep(.el-collapse-item__header) {
        height: 48px;
        font-weight: normal;
        background: var(--detect-config-bg);
        border-bottom: 1px solid var(--color-border-2);
        &.is-active {
          .sand-tableCollapse__header .sand-tableCollapse__header--item .sand-tableCollapse__header--text {
            color: var(--color-text-1);
          }
        }
        .el-collapse-item__arrow {
          color: var(--color-text-3);
          &.is-active {
            color: var(--color-text-1);
          }
        }
        &:hover {
          & .el-icon-arrow-right:before {
            filter: brightness(--svg-filter2);
          }
        }
      }
      .sand-tableCollapse__header--text {
        width: 100%;
        line-height: 18px;
        font-size: 14px;
        color: var(--color-text-2);
        height: 48px;
        display: flex;
        align-items: center;
        &>span {
          padding-left: 10px;
          padding-right: 10px;
          box-sizing: border-box;
          align-items: center;
        }
        .font {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .detect {
          margin: 0 auto;
        }
        svg {
          font-size: 16px;
          &:hover {
            color: var(--color-text-1);
          }
        }
      }
      .sand-tableCollapse__header--tags {
        border: 1px solid;
        border-radius: 2px;
        font-size: 12px;
        text-align: center;
        padding: 4px 12px;
        margin-left: 24px;
      }
      .sand-tableCollapse__header--dst {
        color: var(--color-primary);
        cursor: pointer;
      }
      .sand-tableCollapse__header--domains {
        width: 100%;
        display: flex;
        color: var(--color-primary);
        .none {
          color: var(--color-text-2);
        }
        .link {
          overflow: hidden !important;
          text-overflow: ellipsis !important;
          white-space: nowrap !important;
        }
        .more {
          width: 20px;
          border-radius: 2px;
          border: 1px solid var(--color-border-2);
          padding: 2px 4px;
          font-size: 12px;
          margin-left: 8px;
          text-align:center;
          background: var(--color-bg-3);
          color: var(--color-text-2);
          &:hover {
            color: var(--color-primary);
          }
        }
      }
      .margin12 {
        margin-left: 12px;
      }
      .sandNetwork-row {
        display: flex;
        border-bottom: 1px solid var(--color-border-2);
        // bd_color(color-sand-border);
        align-items: center;
        .row-col {
          flex: 1;
          padding: 10px;
          line-height: 20px;
          white-space: break-spaces;
          word-break: break-all;
        }
      }
      .sand-tableCollapse__header {
        width: calc(100% - 65px);
        .sand-tableCollapse__header--item {
          width: 100%;
          padding-left: 15px;
          display: flex;
          span {
            // padding: 10px;
          }
        }
      }
      :deep(.el-collapse) {
        .el-collapse-item__content {
          padding: 0;
        }
      }
      .content {
        padding: 16px;
        background: var(--color-bg-3);
        .h2 {
          font-size: 16px;
          color: var(--color-text-2);
          box-shadow: 0 1px 0 0 var(--color-border-2);
          padding-bottom: 8px;
        }
        .detail {
          // margin-top: 20px;
          .item {
            display: flex;
            margin-top: 10px;
            .label {
              font-size: 14px;
              color: var(--color-text-3);
              font-weight: 500;
              min-width: 80px;
              margin-right: 40px;
              line-height: 2;
            }
            .value {
              flex: 1;
              width: calc(100% - 120px);
              font-size: 14px;
              color: var(--color-text-2);
              display: flex;
              align-items: center;
              &.process {
                display: flex;
                gap: 8px;
                flex-flow: wrap;
                font-size: 12px;
                margin-top: 2px;
                & > div {
                  height: 22px;
                  border-radius: 2px;
                  border: 1px solid var(--color-primary);
                  color: var(--color-primary);
                  padding: 0px 8px;
                  cursor: pointer;
                  .gap {
                    margin: 0 4px;
                  }
                }
              }
              svg {
                margin-left: 6px;
                font-size: 16px;
                color: var(--color-text-3);
                cursor:pointer;
                &:hover {
                  color: var(--color-text-1);
                }
              }
              div {
                white-space: pre-wrap;
              }
              .font {
                word-break: break-all;
              }
            }
          }
        }
        .code-pre {
          overflow-y: auto;
          padding: 0 16px;
          max-height: 200px;
          border: 1px solid var(--color-border-2);
          border-radius: 2px;
          color: var( --color-text-1);
          pre {
            white-space: pre-wrap;
            line-height: 20px;
            // white-space: pre-line;
            word-break: break-all;
            font-size: 14px;
            // font-family: 'CONSOLA';
          }
        }
      }
      .content + .content {
        margin-top: -16px; /* 抵消上一个元素的 padding-bottom */
      }
    }
    .pagination-wrap {
      padding: 10px 0px 10px 10px;
      margin-right: -20px;
      text-align: right;
    }
    .network-detail {
      .detail {
        .item {
          display: flex;
          margin-top: 10px;
          &:first-child {
            margin-top: 0 !important;
          }
          .label {
            font-size: 14px;
            color: var(--color-text-3);
            font-weight: 500;
            min-width: 80px;
            margin-right: 40px;
          }
          .value {
            flex: 1;
            width: calc(100% - 120px);
            font-size: 14px;
            color: var(--color-text-2);
            display: flex;
            align-items: center;
            svg {
              margin-left: 6px;
              font-size: 16px;
              color: var(--color-text-3);
              cursor:pointer;
              &:hover {
                color: var(--color-text-1);
              }
            }
            div {
              white-space: pre-wrap;
              line-height: 1.7;
            }
            .font {
              word-break: break-all;
            }
          }
        }
      }
    }
  }
</style>
